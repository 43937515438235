import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
var isDataFilled = false;

export default function Blog() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await axios(
      "https://miracleinside.info/wp-json/wp/v2/blogs"
    );
    setData(result.data);
  };
  const TruncateLongString = (str) => {
    return str.length > 165 ? str.slice(0, 165 - 1) + "&hellip;" : str;
  };
  const getFormattedDate = (item) => {
    if (item && item.modified && item.modified !== "") {
      return new Date(item.modified).toLocaleDateString();
    }
    return "https://www.google.com";
  };
  return (
    <section className="blog-grid pb-50">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-40">
              <h2 className="heading__subtitle">Resource Library</h2>
              <h2 className="heading__title">Recent Blogs</h2>
            </div>
            {/* /.heading */}
          </div>
          {/* /.col-lg-6 */}
        </div>
        {/* /.row */}
        <div className="row">
          {/* Post Item #1 */}

          {data?.slice(0, 3).map((item, index) => (
            <div             
              key={index}
              className="col-sm-12 col-md-6 col-lg-4"
            >
              <div className="post-item">
                <div className="post__img">
                  <Link to={`/blogs/${item?.id}/${item?.slug}`}>
                    <img
                      src={
                        item?.acf?.imageurl
                          ? item.acf.imageurl
                          : "images/blog/grid/1.jpg"
                      }
                      alt="mi"
                      loading="lazy"
                    />
                  </Link>
                </div>
                {/* /.post__img */}
                <div className="post__body">
                  <div className="post__meta-cat">
                    <Link
                      to={`/blogs/${item?.id}/${item?.slug}`}
                      dangerouslySetInnerHTML={{ __html: item.acf.category }}
                    ></Link>
                  </div>
                  {/* /.blog-meta-cat */}
                  <div className="post__meta d-flex">
                    <span className="post__meta-date">
                      {getFormattedDate(item)}
                    </span>
                    <Link
                      className="post__meta-author"
                      to={`/blogs/${item?.id}/${item?.slug}`}
                      dangerouslySetInnerHTML={{ __html: item.acf.written_by }}
                    ></Link>
                  </div>
                  <h4 className="post__title">
                    <Link
                      to={`/blogs/${item?.id}/${item?.slug}`}
                      dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                    ></Link>
                  </h4>
                  <p
                    className="post__desc"
                    dangerouslySetInnerHTML={{ __html: TruncateLongString(item.content.rendered)}}
                  ></p>
                  <Link
                    to={`/blogs/${item?.id}/${item?.slug}`}
                    className="btn btn__secondary btn__link btn__rounded"
                  >
                    <span>Read More</span>
                    <i className="icon-arrow-right" />
                  </Link>
                </div>
                {/* /.post__body */}
              </div>
              {/* /.post-item */}
            </div>
          ))}

<Link to="/blogs" className="btn btn__secondary btn__link">
                <i class="icon-arrow-right icon-filled"></i>
                <span>Read all Blogs</span>
              </Link>
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
}
