import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "../Home/Home";
import Header from "../Home/Header/Header";
import About from "./About";
import BloodTest from "./BloodTest";
import Contact from "./Contact";
import BloodTestDetails from "./DetailsPages/BloodTestDetails";
import ScanDetails from "./DetailsPages/ScanDetails";
import Scan from "./Scan";
import axios from "axios";
import DynamicPage from "./DynamicPage";
import { useQuery } from "react-query";
import Faq from "./Faq";
import BookScan from "./ScanBookingForm";
import BookBlood from "./BloodBookingForm";
import Blog from "./DetailsPages/Blog";
import ReactGA from "react-ga4";

import BlogDetails from "./DetailsPages/BlogDetails";
import Header2 from "../Home/Header/Header2";
import OpenFranchise from "./OpenFranchise";
import FertilityClinic from "./FertilityClinic";
import FertilityClinicDetails from "./DetailsPages/FertilityClinicDetails";

function DynamicRoutes() {
  const location = useLocation();
  const { data, status } = useQuery("dataNav", () =>
    axios.get("https://miracleinside.info/wp-json/myroutes/menu")
  );

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    window.scrollTo(0, 0);
  }, [location]);

  const navigationHandler = (item, index) => {
    switch (item.url) {
      case "/":
        return <Route key={index} path="/" element={<Home />} />;
      case "/scan":
        return <Route key={index} path="/scan" element={<Scan />} />;
      case "/bloodtest":
        return <Route key={index} path="/bloodtest" element={<BloodTest />} />;
      case "/contact":
        return <Route key={index} path="/contact" element={<Contact />} />;
      case "/franchise":
          return <Route key={index} path="/franchise" element={<OpenFranchise />} />;  
      default:
        if (item.type_label === "Page") {
          let tempPath = item.url.split("/");
          return (
            <Route
              key={index}
              path={`/${tempPath[tempPath.length - 2]}`}
              element={<DynamicPage id={item.object_id} />}
            />
          );
        }
        if (item.url.split("/")[2] === "faq") {
          return <Route key={index} path="/:id/faq" element={<Faq />} />;
        } else {
          return (
            <Route
              key={index}
              path={item.url}
              element={<About id={item?.url.split("/")[1]} />}
            />
          );
        }
    }
  };
  return (
    <>
      <Header2 navbar={status === "success" && data?.data} />
      {/* <Header navbar={status === "success" && data?.data} /> */}
      <Routes>
        {status === "success" &&
          data?.data.length > 0 &&
          data.data.map(navigationHandler)}
        <Route path="/" element={<Home />} />
        <Route path="/bloodtest/:id/:slug" element={<BloodTestDetails />} />
        <Route path="/scan/:id/:slug" element={<ScanDetails />} />
        <Route path="/:id/faq" element={<ScanDetails />} />
        <Route path="/franchise" element={<OpenFranchise />} />
        <Route path="/fertility-clinic" element={<FertilityClinic />} />
        <Route path="/fertility-clinic/:id" element={<FertilityClinicDetails />} />
        <Route path="/bookscan" element={<BookScan />} />
        <Route path="/bookscan/:name" element={<BookScan />} />
        <Route path="/bookbloodtest/:name" element={<BookBlood />} />
        <Route path="/bookbloodtest" element={<BookBlood />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:id/:slug" element={<BlogDetails />} />
      </Routes>
    </>
  );
}

export default DynamicRoutes;
