function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
export const convertDateToFormat = (date) => {
  if (date) {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/");
  }
};
