import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Footer from "../Home/Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Metatags from "../MetaTags/Metatags";

function DynamicPage({ id }) {
  const [data, setdata] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://miracleinside.info/wp-json/wp/v2/pages/${id}`
      );
      if (result?.data) {
        setdata(result.data);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="canonical" href="https://miracleinside.com" />
        </Helmet>
      </HelmetProvider>
      {data?.acf?.meta_title && data?.acf?.meta_description ? (
        <Metatags
          title={data?.acf?.meta_title}
          description={data?.acf?.meta_description}
        />
      ) : null}
      <SliderInner image={data?.acf.image_url} />
      <section className="about-layout1 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="heading-layout2">
                <h1
                  className="heading__title mb-40"
                  dangerouslySetInnerHTML={{ __html: data?.title.rendered }}
                ></h1>
              </div>
              {/* /heading */}
            </div>
            {/* /.col-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="about__Text">
                <p
                  className="mb-30"
                  dangerouslySetInnerHTML={{ __html: data?.content.rendered }}
                ></p>
              </div>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <Footer />
    </>
  );
}

export default DynamicPage;
