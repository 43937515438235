import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../Home/Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import { metaDataApi } from "../../utils/metaDataApi";
import Metatags from "../MetaTags/Metatags";

const FertilityClinic = () => {
  const [data, setData] = useState(null);
  const [imgData, setImgData] = useState("");
  const [content, setContent] = useState();
  useEffect(() => {
    getFertilityClinic();
  }, []);

  const getFertilityClinic = async () => {
    const res = await axios.get(
      "https://miracleinside.info/wp-json/wp/v2/fertility_clinics"
    );
    const resD = await axios.get(
      "https://miracleinside.info/wp-json/wp/v2/homeslider/988"
    );
    const dataContent = await metaDataApi(989);
    if (dataContent !== -1) setContent(dataContent);
    if (resD?.data) setImgData(resD.data.acf.slider_image_url);
    if (res?.data?.length) setData(res?.data);
  };
  return (
    <>
      <SliderInner image={imgData} />
      <Metatags title={content?.title?.rendered} />

      <div className="container mt-2">
        <p
          className="text-block__desc mb-20 font-weight-bold color-secondary"
          dangerouslySetInnerHTML={{ __html: content?.content?.rendered }}
        ></p>

        <div className="row my-4">
          {data?.map((item, index) => (
            <div key={index} className="col-12 col-lg-4 col-md-6">
              <div
                className="post-item card h-100 d-flex justify-content-between"
                style={{ minHeight: "400px" }}
              >
                <div className="post__img">
                  <a href={`/fertility-clinic/${item?.id}`}>
                    <img
                      src={
                        item?.acf?.image
                          ? item.acf.image
                          : "images/blog/grid/1.jpg"
                      }
                      alt="mi"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div className="post__body">
                  <div className="post__meta-cat"></div>
                  <h4 className="post__title">
                    <a href={`/fertility-clinic/${item?.id}`}>{item?.title?.rendered}</a>
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.content?.rendered?.length > 200
                          ? item?.content?.rendered
                              ?.replace(/^<p>|<\/p>$/g, "")
                              ?.slice(0, 200) + "..."
                          : item?.content?.rendered?.replace(
                              /^<p>|<\/p>$/g,
                              ""
                            ),
                    }}
                  />

<div className="product__quantity d-flex mt-30">
                  <a style={{minWidth: "130px"}}
                     
                     href={`/fertility-clinic/${item?.id}`}
                     className="btn btn__secondary btn__rounded mr-20"
                   >
                    Read more
                   </a>
                   <a style={{minWidth: "130px"}}
                    
                     href={item?.acf?.website_link}
                     className="btn btn__secondary btn__rounded"
                   >
                     Visit Website
                   </a>
</div>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FertilityClinic;
