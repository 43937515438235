import React, { useState, useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import axios from "axios";
import "../Pages/BloodTest.css";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import Metatags from "../MetaTags/Metatags";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { metaDataApi } from "../../utils/metaDataApi";

export default function BloodTest() {
  const [sortedData, setSortedData] = useState([]);
  const { data, status } = useQuery("bloodtests", () =>
    axios("https://miracleinside.info/wp-json/wp/v2/blood_test?per_page=100")
  );
  const [metaData, setMetaData] = useState({
    name: "",
    description: "",
    content: "",
  });
  const [bannerimg, setBannerimg] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://miracleinside.info/wp-json/wp/v2/homeslider/374"
      );
      if (result.data?.id) {
        setBannerimg(result.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data?.data) {
      sortData();
    }
  }, [data]);

  const sortData = () => {
    let tempDataPriorityValue = [];
    let tempDataWithoutValue = [];

    data?.data?.map((scan) => {
      if (scan?.acf?.priority) {
        tempDataPriorityValue = [...tempDataPriorityValue, scan];
        return;
      } else {
        tempDataWithoutValue = [...tempDataWithoutValue, scan];
        return;
      }
    });
    let sortedScansTemp = tempDataPriorityValue.sort((p1, p2) =>
      p1.acf.priority > p2.acf.priority
        ? 1
        : p1.acf.priority < p2.acf.priority
        ? -1
        : 0
    );
    console.log({ sortedScansTemp });
    setSortedData([...sortedScansTemp, ...tempDataWithoutValue]);
  };
  useEffect(() => {
    getMetaResp();
  }, []);
  const getMetaResp = async () => {
    const metaData = await metaDataApi(576);
    if (metaData !== -1) {
      setMetaData({
        name: metaData.acf.meta_title,
        description: metaData.acf.meta_description,
        content: metaData.content.rendered,
      });
    }
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="canonical" href="https://miracleinside.com/bloodtest" />
        </Helmet>
      </HelmetProvider>

      <Metatags title={metaData?.name} description={metaData?.description} />

      <SliderInner image={bannerimg?.acf.slider_image_url} />

      <section className="pricing pt-0 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <br />
              <div
                className="text-block__desc mb-20 font-weight-bold color-secondary"
                dangerouslySetInnerHTML={{ __html: metaData?.content }}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Blood Test"
                  id="contact-name"
                  name="search"
                  required=""
                  aria-required="true"
                />
              </div>
            </div>
          </div> */}

          <div>
            <div className="table-wrapper">
              <table className="fl-table">
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Price</th>
                    <th>Lab Results</th>
                    <th>Read More</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/bloodtest/${item.id}/${item.slug}`}>
                          {item.title.rendered}
                        </Link>
                        {/* <a href={GetItemUrl(item.link)} dangerouslySetInnerHTML={{ __html: item.title.rendered }}></a> */}
                      </td>

                      <td>
                        <div className="package__price">
                          <span className="package__currency">£</span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.acf?.amount,
                            }}
                          ></span>
                        </div>
                      </td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: item.acf.result_days
                            ? item.acf?.result_days?.toString().concat(" days")
                            : " ",
                        }}
                      ></td>
                      <td>
                        <Link to={`/bloodtest/${item.id}/${item.slug}`}>
                          Read More
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
        {/* /.container */}
      </section>

      <Footer />
    </>
  );
}
