import { QueryClient, QueryClientProvider } from "react-query";
import DynamicRoutes from "./components/Pages/DynamicRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";

function App() {
  const queryClient = new QueryClient();
  const TRACKING_ID = "G-F4J3MTZQJD"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="wrapper">
        <Router>
          <DynamicRoutes />
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;

{
  /* <div className="preloader">
<div className="loading">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
</div> */
}
