import React, { useEffect, useState } from 'react'
import { Modal, Box } from "@mui/material";


const style = {
  fontFamily: "Montserrat",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxWidth: "90%",
  border: "10px solid #213360",
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;",
  padding: "0 0 20px",
  textAlign: "center",
  background: "#fff",
  boxsizing: "border-box"
};

const imgstyle = {
  objectfit: "contain",
  height: "auto",
  width: "auto",
}

export default function Modalwindow() {

    const   [displayPopUp, setdisplayPopUp] = useState(true);

    const closePopUp = () => {
        localStorage.setItem("seenPopUp",true);
        setdisplayPopUp(false);
    }

    useEffect(() => {
let returninguser = localStorage.getItem("seenPopUp");

setdisplayPopUp(!returninguser);
    },[]);

  return (
<>

        {displayPopUp && (
          <Modal
            open={true}
// once pop-up will close "closePopUp" function will be executed
            onClose={closePopUp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
{/* what user will see in the modal is defined below */}
<img src='https://miracleinside.info/wp-content/uploads/2024/09/popupposter.jpg' style={imgstyle} alt='Moving to new location'/>
              <button className='btn btn__secondary btn__rounded mt-20' onClick={closePopUp}>Thanks!</button>
            </Box>
          </Modal>
        )}

</>
  )
}
