import React, { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer/Footer";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";
import Metatags from "../MetaTags/Metatags";
import { metaDataApi } from "../../utils/metaDataApi";
function Contact({ onlyForm = false }) {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [isVerified, setIsVerified] = useState(false);
  const [metaData, setMetaData] = useState({
    name: "",
    description: "",
  });

  const getMetaResp = async () => {
    const metaData = await metaDataApi(584);
    if (metaData !== -1) {
      setMetaData({
        name: metaData.acf.meta_title,
        description: metaData.acf.meta_description,
      });
    }
  };
  const [formData, setFormData] = useState({
    "your-name": "",
    "your-email": "",
    "your-subject": "Calback",
    "your-message": "",
    "your-number": "",
  });
  const [data, setData] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setLoading(true);
    if (!isVerified) {
      alert("Please verify captcha to continue");
      setLoading(false);
      return;
    }
    onFormSubmit();
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://miracleinside.info/wp-json/wp/v2/posts/95"
      );
      setData(result.data.content);
    };

    fetchData();
    getMetaResp();
  }, []);

  const inputOnchange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onFormSubmit = async () => {
    var form_data = new FormData();
    Object.keys(formData).forEach((key) =>
      form_data.append(key, formData[key])
    );

    try {
      const response = await axios.post(
        "https://miracleinside.info/wp-json/contact-form-7/v1/contact-forms/273/feedback",
        form_data
      );
      console.log(response);
      if (response.data?.status == "mail_sent") {
        setSuccessMessage(response.data.message);
        executeScroll();
      } else {
        alert(response?.data.message);
      }
      setLoading(false);
    } catch {
      alert("something went wrong.");
    }
  };
  const onChange = (val) => {
    setIsVerified(true);
  };
  return (
    <>
      <Metatags title={metaData?.name} description={metaData?.description} />

      {!onlyForm && (
        <section className="google-map py-0">
          <iframe
            title="myFrame"
            frameBorder={0}
            height={500}
            width="100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2357.813723854456!2d-1.5317319!3d53.775003399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795c33ce68c797%3A0x151dc4df828a47f0!2sMiracle%20Inside%203D%2F4D%20Baby%20Scan%20Centre!5e0!3m2!1sen!2sca!4v1664391797673!5m2!1sen!2sca"
          />
        </section>
      )}
      <section
        className={`contact-layout1 pt-0 ${!onlyForm ? "mt--100" : "mt-4"}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-panel d-flex flex-wrap">
                {successMessage ? (
                  <h4 className="contact-panel__form">{successMessage}</h4>
                ) : (
                  <Form onSubmit={handleSubmit} className="contact-panel__form">
                    <Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <div className="form-group">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            onChange={inputOnchange}
                            name="your-name"
                            required
                            type="text"
                            placeholder="First name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter first name
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <div className="form-group">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            onChange={inputOnchange}
                            name="your-email"
                            required
                            type="text"
                            placeholder="Email"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter Email
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <div className="form-group">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            onChange={inputOnchange}
                            name="your-number"
                            required
                            type="text"
                            placeholder="Phone Number"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter first name
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <div className="form-group">
                          <Form.Label>Subject</Form.Label>
                          <Form.Select
                            onChange={inputOnchange}
                            name="your-subject"
                            className="form-control"
                          >
                            <option value="Calback">Calback</option>
                            <option value="Compliments">Compliments</option>
                            <option value="Complaints">Complaints</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please enter Subject
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <div className="form-group">
                          <Form.Label>Message</Form.Label>
                          <Form.Control
                            onChange={inputOnchange}
                            name="your-message"
                            as="textarea"
                            rows={6}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Row>
                    <ReCAPTCHA
                      className="mb-4 mt-4"
                      sitekey="6Lc_lg8UAAAAANqdYBoOiGrlf1mclhIJx9f3-4vF"
                      onChange={onChange}
                    />
                    <Button disabled={loading} type="submit">
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </Form>
                )}
                <div
                  ref={myRef}
                  className="contact-panel__info d-flex flex-column justify-content-between bg-overlay bg-overlay-primary-gradient bg-img"
                  style={{
                    backgroundImage: 'url("images/banners/1.jpg")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: data.rendered }}
                  ></div>
                </div>
              </div>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {!onlyForm && <Footer />}
    </>
  );
}

export default Contact;