import React, { useState, useEffect } from "react";

import Footer from "../Home/Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import Metatags from "../MetaTags/Metatags";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { metaDataApi } from "../../utils/metaDataApi";

export default function Scan() {
  const [sortedData, setSortedData] = useState([]);
  const [metaData, setMetaData] = useState({
    name: "",
    description: "",
    content: ""
  });

  const { data } = useQuery("scans", () =>
    axios("https://miracleinside.info/wp-json/wp/v2/scans?per_page=100")
  );
  const [bannerimg, setBannerimg] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://miracleinside.info/wp-json/wp/v2/homeslider/376"
      );
      if (result.data?.id) {
        setBannerimg(result.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data?.data) {
      sortData();
    }
  }, [data]);
  useEffect(() => {
    getMetaResp();
  }, []);
  const getMetaResp = async () => {
    const metaData = await metaDataApi(574);
    if (metaData !== -1) {
      setMetaData({
        name: metaData.acf.meta_title,
        description: metaData.acf.meta_description,
        content: metaData.content.rendered,
      });
    }
  };
  const sortData = () => {
    let tempDataPriorityValue = [];
    let tempDataWithoutValue = [];

    data?.data?.map((scan) => {
      if (scan?.acf?.priority) {
        tempDataPriorityValue = [...tempDataPriorityValue, scan];
        return;
      } else {
        tempDataWithoutValue = [...tempDataWithoutValue, scan];
        return;
      }
    });
    let sortedScansTemp = tempDataPriorityValue.sort((p1, p2) =>
      p1.acf.priority > p2.acf.priority
        ? 1
        : p1.acf.priority < p2.acf.priority
        ? -1
        : 0
    );
    setSortedData([...sortedScansTemp, ...tempDataWithoutValue]);
  };

  const getBookNowLink = (item) => {
    if (
      item &&
      item.acf &&
      item.acf.booking_url &&
      item.acf.booking_url !== ""
    ) {
      return item.acf.booking_url;
    }
   // return `/bookscan/${item.title.rendered}`;
   return "https://www.123formbuilder.com/form-5248378/online-order-form";
  };
  const TruncateLongString = (str) => {
    return str.length > 88 ? str.slice(0, 88 - 1) + "&hellip;" : str;
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="canonical" href="https://miracleinside.com/scan" />
        </Helmet>
      </HelmetProvider>
      <Metatags title={metaData?.name} description={metaData?.description} />

      <SliderInner image={bannerimg?.acf.slider_image_url} />

      <section className="pricing pt-0 pb-80">
        <div className="container">

          
          <p className="text-block__desc mb-20 font-weight-bold color-secondary" dangerouslySetInnerHTML={{__html :metaData?.content}}></p>

          <div className="row row-no-gutter packages-wrapper" style={{marginTop:30}}>
            {/* pricing item #1*/}
            {sortedData?.map((item, index) => (
              <div key={index} className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricing-package">
                  <div>
                    <Link to={`/scan/${item.id}/${item.slug}`}>
                      <h5
                        className="package__title"
                        dangerouslySetInnerHTML={{
                          __html: item.title.rendered,
                        }}
                      ></h5>
                    </Link>
                    <p
                      className="package__desc"
                      dangerouslySetInnerHTML={{
                        __html: TruncateLongString(item.content.rendered),
                      }}
                    ></p>
                    <ul className="package__list list-items list-items-layout2 list-unstyled">
                      <li
                        dangerouslySetInnerHTML={{ __html: item.acf.feature1 }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{ __html: item.acf.feature2 }}
                      ></li>
                      <li
                        dangerouslySetInnerHTML={{ __html: item.acf.feature3 }}
                      ></li>
                    </ul>
                  </div>
                  <div className="package__footer">
                    <div className="package__price">
                      <span className="package__currency">£</span>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.acf.amount }}
                      ></span>
                    </div>
                    <a
                      href={getBookNowLink(item)}
                      className="btn btn__secondary btn__rounded"
                    >
                      Book now
                    </a>
                  </div>
                  {/* /.package__footer */}
                </div>
                {/* /.pricing-package */}
              </div>
            ))}
          </div>
          {/* /.row */}

          {/* <div className="row">
              <div className="col-12 text-center">
                <p className="text__link mb-0">Delivering tomorrow’s health care for your family.
                  <a href="doctors-timetable.html" className="btn btn__secondary btn__link mx-1">
                    <span>View Doctors’ Timetable</span> <i className="icon-arrow-right icon-outlined" />
                  </a>
                </p>
              </div>
            </div> */}
        </div>
        {/* /.container */}
      </section>

      <Footer />
    </>
  );
}
