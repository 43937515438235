import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar2 from "../../Navigation/Navbar2";
import "./header.css";

function Header2({ navbar }) {
  const [contactinfo, setContactinfo] = useState({
    content: { rendered: null },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("https://miracleinside.info/wp-json/wp/v2/posts/1")
      .then((res) => {
        setContactinfo(res.data);
      });
  };
  return (
    <div>
      <div className="header-topbar-custom">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: contactinfo.content.rendered,
            }}
          ></div>
          <div className="d-flex">
            <ul className="social-icons list-unstyled mb-0 mr-30">
              <li>
                <a href="https://www.facebook.com/miracleinside4Dbabyscan/">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Miracleinside4D">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/miracle-inside-397149b8">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
            </ul>
            {/* /.social-icons */}
          </div>
          {/* /.col-12 */}
        </div>
      </div>
      <div
        className="container-fluid"
        style={{ display: "flex", background: "white" }}
      >
        <a href="/">
          <img
            src="../../../images/logo/milight2.png"
            style={{ height: "80px" }}
            className="logo-light"
            alt="logo"
          />
        </a>

        {navbar?.length > 0 && <Navbar2 navbar={navbar} />}
      </div>
    </div>
  );
}

export default Header2;

{
  /* <button className="close-mobile-menu d-block d-lg-none">
              <i className="fas fa-times" />
            </button> */
}

{
  /* <button className="navbar-toggler" type="button">
            <span className="menu-lines">
              <span />
            </span>
          </button> */
}
{
  /* <div className="header-topbar">
<div className="container-fluid">
  <div className="row align-items-center">
    <div className="col-12">
      <div className="d-flex align-items-center justify-content-between">
        <div
          dangerouslySetInnerHTML={{
            __html: contactinfo.content.rendered,
          }}
        ></div>
        <div className="d-flex">
          <ul className="social-icons list-unstyled mb-0 mr-30">
            <li>
              <a href="https://www.facebook.com/miracleinside4Dbabyscan/">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Miracleinside4D">
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/miracle-inside-397149b8">
                <i className="fab fa-linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</div> */
}
