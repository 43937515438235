import React from "react";
import { Helmet } from "react-helmet";

function Metatags({ title, description, keyword }) {
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}
      {keyword ? <meta name="keyword" content={keyword} /> : null}
    </Helmet>
  );
}

export default Metatags;
