import React, { useEffect, useState } from "react";
import Footer from "../Home/Footer/Footer";
import axios from "axios";
import SliderInner from "../SliderInner/SliderInner";
import FranchiseContactForm from "../forms/FranchiseContactForm";
import { metaDataApi } from "../../utils/metaDataApi";
import Metatags from "../MetaTags/Metatags";
const OpenFranchise = () => {
  const [bannerimg, setBannerimg] = useState();
  const [metaData, setMetaData] = useState({});
  const [fearures, setFeatures] = useState([]);
  const [investments, setInvestments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await metaDataApi(866);
      if (response !== -1) {
        let keysFeature = Object.keys(response.acf).filter((item) =>
          item.includes("feature")
        );
        let keysInvestments = Object.keys(response.acf).filter((item) =>
          item.includes("investment_details")
        );
        let investmentsArray = keysInvestments.map(
          (item) => response.acf[item]
        );
        let featuresArray = [];
        for (let i = 0; i < keysFeature.length; i += 2) {
          let nameKey = keysFeature[i];
          let descKey = keysFeature[i + 1];

          let featureObject = {
            title: response.acf[nameKey],
            description: response.acf[descKey],
          };

          featuresArray.push(featureObject);
        }

        setInvestments(investmentsArray);
        setFeatures(featuresArray);
        setMetaData({
          title: response.acf.meta_title,
          description: response.acf.meta_description,
          meta_keyword: response.acf.meta_keyword,
          content: response.content?.rendered,
        });
      }
      const result = await axios(
        "https://miracleinside.info/wp-json/wp/v2/homeslider/753"
      );
      if (result.data?.id) {
        setBannerimg(result.data);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Metatags
        title={metaData?.title}
        description={metaData?.description}
        keyword={metaData?.meta_keyword}
      />

      <main>
        <SliderInner image={bannerimg?.acf?.slider_image_url} />
        <div />
        <div
          className="container mt-2"
          dangerouslySetInnerHTML={{ __html: metaData?.content }}
        />

        <div className="container mt-2">
          <div className="d-flex flex-wrap">
            {fearures.map((kf, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div
                  className="card m-2 border-dark border-0 border-top shadow-sm h-100"
                  style={{ borderTop: 20 }}
                >
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{ borderTop: 20, borderColor: "red" }}
                    >
                      {kf.title}
                    </h5>
                    <p className="card-text">{kf.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container mt-2">
          <h4>Franchise Package Highlights</h4>
          {[
            " Comprehensive Operations Support",
            "Comprehensive Operations Support",
            "Optimized Website & SEO Assistance",
            "Digital Marketing Support",
            "Discounted Service Providers",
            "Continuous Guidance & Support",
          ].map((feat) => (
            <ul
              key={feat}
              className="package__list list-items list-items-layout2 list-unstyled"
            >
              <li className="feature__title">{feat}</li>
            </ul>
          ))}
        </div>

        <div className="container mt-2">
          <h4>Franchise Investment Details</h4>
          {investments.map((inv) => (
            <p key={inv}>{inv}</p>
          ))}
        </div>

        <div className="container mt-2">
          <h4>Contact Us</h4>
          <p>
            Take the first step towards joining the MIRACLE INSIDE DIAGNOSTIC
            SCAN franchise by filling out our CONTACT form below to receive our
            comprehensive brochure and learn more about this exciting
            opportunity. Simply provide your details, and we'll get in touch!
          </p>
          <FranchiseContactForm onlyForm={true} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default OpenFranchise;
