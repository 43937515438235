import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const BookNowlink = (item, url) => {
  if (url && url !== "") {
    return url;
  } else return `/bookscan/${item.title.rendered}`;
};

const Slider = ({ data }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="container">
      <h2 className="heading__title">Our Services</h2>
      {data?.length > 0 ? (
        <Carousel
          responsive={responsive}
          autoPlay
          autoPlaySpeed={3000}
          infinite
        >
          {data.map((item, index) => {
            let readmoreURL =
              item?.type === "scans"
                ? `/scan/${item.id}/${item.slug}`
                : `/bloodtest/${item.id}/${item.slug}`;

            let bookingURL =
              item?.type === "scans"
                ? BookNowlink(item, item?.acf?.booking_url)
                : `/bookbloodtest/${item?.title?.rendered}`;
            return (
              <div key={index} className="col-12 ">
                <div
                  className="post-item card h-100 d-flex justify-content-between"
                  style={{ minHeight: "400px" }}
                >
                  <div className="post__img">
                    <Link to={item?.link}>
                      <img
                        src={
                          item?.acf?.image_url
                            ? item.acf.image_url
                            : "images/blog/grid/1.jpg"
                        }
                        alt="mi"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div className="post__body">
                    <div className="post__meta-cat"></div>
                    <h4 className="post__title">
                      <Link to={item?.link}>{item?.title?.rendered}</Link>
                    </h4>
                    <div className="d-flex justify-content-around">
                      <div>
                        <Link
                          to={bookingURL}
                          className="btn btn__secondary btn__link btn__rounded"
                        >
                          <span>Book Now</span>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={readmoreURL}
                          className="btn btn__secondary btn__link btn__rounded"
                        >
                          <span>Read More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      ) : null}
    </div>
  );
};

export default Slider;

// className="btn btn__primary btn__rounded"
