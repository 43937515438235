import React, { useEffect, useRef, useState } from "react";
import Footer from "../Home/Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import axios, { Axios } from "axios";
import { useParams } from "react-router-dom";
import { AccordionCollapse } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDateToFormat } from "../Utils/ConvertDateToFormat";
import ReCAPTCHA from "react-google-recaptcha";
import Metatags from "../MetaTags/Metatags";

export default function BloodBookingForm() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [allBloodTests, setAllBloodTests] = useState([]);
  const [formData, setFormData] = useState({
    "first-name": "",
    "last-name": "",
    "your-email": "",
    "your-phone": "",
    "your-address": "",
    "your-postcode": "",
    "your-moreinfo": "",
    "your-hear": "",
    "your-appointmenttime": "Morning",
    "your-bloodtest": params?.name,
  });
  const [dateOfBirth, setDateOfBirth] = useState();
  const [dateOfAppointment, setDateOfAppointment] = useState();
  const [validated, setValidated] = useState(false);
  const myRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false);

  const executeScroll = () => myRef.current.scrollIntoView();

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setLoading(true);
    if (!isVerified) {
      alert("Please verify captcha to continue");
      setLoading(false);
      return;
    }
    onFormSubmit();

    setValidated(true);
  };

  useEffect(() => {
    getBloodTests();
  }, []);

  const getBloodTests = async () => {
    const response = await axios(
      "https://miracleinside.info/wp-json/wp/v2/blood_test?per_page=100"
    );
    if (response?.data) {
      setAllBloodTests(response.data);
    } else alert("Something went wrong please check your internet connection");
  };
  const inputOnchange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onFormSubmit = async () => {
    console.log("submit form is running");

    var form_data = new FormData();
    Object.keys(formData).forEach((key) =>
      form_data.append(key, formData[key])
    );
    form_data.append("your-dob", convertDateToFormat(dateOfBirth));
    form_data.append(
      "your-appointmentdate",
      convertDateToFormat(dateOfAppointment)
    );
    try {
      const response = await axios.post(
        "https://miracleinside.info/wp-json/contact-form-7/v1/contact-forms/335/feedback",
        form_data
      );
      console.log(response);
      if (response.data?.status == "mail_sent") {
        setSuccessMessage(response.data.message);
        executeScroll();
      } else {
        alert(response.data.message);
      }
      setLoading(false);
    } catch {
      alert("something went wrong.");
    }
  };
  const onChange = (val) => {
    console.log("captcha function is running");

    setIsVerified(true);
  };
  return (
    <>
      <SliderInner />
      <Metatags title={"Blood Test Booking"} />
      <section>
        <div className="container">
          <>
            <div className="main-content">
              <h2>&nbsp;Blood Test Booking Enquiry Form</h2>
              <p ref={myRef}>
                Miracle Inside now offers a comprehensive blood testing service
                (prices on enquiry), from Antenatal blood screening, tumour
                markers, thyroid functions, hormones, women&rsquo;s and
                men&rsquo;s health screens, blood tests for IVF treatment, blood
                group and any blood test
              </p>
              <p>
                Please fill the below enquiry form, Your email address only for
                us to contact you. No information gathered from this page is
                transmitted to any third parties.
              </p>
              <p>
                Our staff will call you in your preferred time or Alternatively
                you can call our bookings team on 01132008760 / 01133226110
                directly to book your appointment.
              </p>
              <p>
                Please also answer to these in the MORE INFO Space Field :
                Ethnicity , Approx. Gestational age , Family History of these
                Genetic Conditions , Abnormal Ultrasound ( NT scan) , Blood test
                Risk Score if you had one with the NHS , Maternal weight ,
                Maternal Height , IVF Pregnancy and (Consanguinity is defined as
                a union between two individuals who are related as second
                cousins or closer)
              </p>
            </div>
          </>
          {successMessage ? (
            <h4>{successMessage}</h4>
          ) : (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <div className="form-group">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="first-name"
                      required
                      type="text"
                      placeholder="First name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter first name
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <div className="form-group">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="last-name"
                      required
                      type="text"
                      placeholder="Last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter last name
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="formGroupEmail">
                  <div className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-email"
                      required
                      type="email"
                      placeholder="Enter email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter email
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <div className="form-group">
                    <Form.Label>Date of Birth</Form.Label>

                    <DatePicker
                      className="form-control"
                      placeholderText="MM-DD-YYYY"
                      selected={dateOfBirth}
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={100}
                      maxDate={new Date()}
                      scrollableYearDropdown
                      onChange={(date) => setDateOfBirth(date)}
                      required
                    />

                    <Form.Control.Feedback type="invalid">
                      Please enter date of birth
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom04">
                  <div className="form-group">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-phone"
                      type="text"
                      placeholder="Phone"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter phone
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-address"
                      type="text"
                      placeholder="Address"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter address
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Post Code</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-postcode"
                      type="text"
                      placeholder="Post Code"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid post code.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Appointment Date</Form.Label>

                    <DatePicker
                      className="form-control"
                      placeholderText="MM-DD-YYYY"
                      selected={dateOfAppointment}
                      onChange={(date) => setDateOfAppointment(date)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a Appointment Date.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Appointment Time</Form.Label>
                    <Form.Select
                      onChange={inputOnchange}
                      name="your-appointmenttime"
                      className="form-control"
                    >
                      <option selected value="Morning">
                        Morning
                      </option>
                      <option value="Afternoon">Afternoon</option>
                      <option value="Evening">Evening</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide a Appointment Time
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Preferred blood test</Form.Label>
                    {allBloodTests?.length > 0 ? (
                      <Form.Select
                        onChange={inputOnchange}
                        name="your-bloodtest"
                        className="form-control"
                        defaultValue={params?.name}
                      >
                        {allBloodTests?.map((bloodTest) => (
                          <option
                            key={bloodTest.id}
                            value={bloodTest.title.rendered}
                          >
                            {bloodTest.title.rendered}
                          </option>
                        ))}
                      </Form.Select>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  md="8"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="form-group">
                    <Form.Label>
                      More info : Please enter blood test Risk Score if you had
                      one with the NHS , Maternal weight , Maternal Height , IVF
                      Pregnancy and Consanguinity
                    </Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-moreinfo"
                      as="textarea"
                      rows={3}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter details
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="8" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Where did you hear about us?</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-hear"
                      type="text"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <ReCAPTCHA
                className="mb-4 mt-4"
                sitekey="6Lc_lg8UAAAAANqdYBoOiGrlf1mclhIJx9f3-4vF"
                onChange={onChange}
              />
              <Button disabled={loading} type="submit">
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Form>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
}
