import React, { useState } from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "./getDimensionHook";
import "./navbar.css";
const Navbar2 = ({ navbar }) => {
  const navFiltered = navbar?.filter((item) => item.type_label !== "Page");
  const { height, width } = useWindowDimensions();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          opacity: 1,
          color: "#fff",
        }}
      >
        {width > 1000 ? (
          <ul
            className="row justify-content-center align-items-center"
            style={{ marginBottom: "0px" }}
          >
            {navFiltered?.length > 0 &&
              navFiltered.map((item, index) => (
                <li
                  className="nav-item-expanded"
                  key={index}
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    display: "block",
                    position: "relative",
                    lineHeight: "100px",
                    letterSpacing: ".4px",
                    paddingRight: "15px",
                    marginRight: "20px",
                    background: "white",
                  }}
                >
                  <Link to={`${item?.url}`}>{item?.post_title}</Link>
                </li>
              ))}
            <li
              style={{
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "capitalize",
                display: "block",
                position: "relative",
                lineHeight: "100px",
                letterSpacing: ".4px",
                paddingRight: "15px",
                marginRight: "20px",
              }}
            >
              <a
                href="https://miracleinside3d4dbabyscancentre.gettimely.com/Booking/Location/89781?mobile=True"
                className="btn btn__primary btn__rounded ml-30"
              >
                <span>Book Now</span>
              </a>
            </li>
          </ul>
        ) : (
          <>
            <button type="button" onClick={showSidebar}>
              <i
                className="fa fa-bars"
                aria-hidden="true"
                style={{ fontSize: "25px" }}
              />
            </button>
            <>
              <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
                <ul className="nav-menu-items" onClick={showSidebar}>
                  <li
                    className="navbar-toggle"
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <i
                      className="fa fa-window-close"
                      aria-hidden="true"
                      style={{
                        fontSize: "25px",
                        color: "#fff",
                        marginRight: "20px",
                      }}
                    />
                  </li>

                  {navFiltered.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={"nav-text"}
                        style={{
                          fontSize: "15px",
                          fontWeight: "700",
                          textTransform: "capitalize",
                          display: "block",
                          position: "relative",
                          letterSpacing: ".4px",
                        }}
                      >
                        <Link to={`${item?.url}`}>{item?.post_title}</Link>
                      </li>
                    );
                  })}
                  <li
                    className=""
                    style={{
                      display: "flex",
                      position: "relative",
                      letterSpacing: ".4px",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <a
                      href="https://miracleinside3d4dbabyscancentre.gettimely.com/Booking/Location/89781?mobile=True"
                      className="btn btn__primary btn__rounded "
                      style={{
                        paddingLeft: "5px",
                        height: "40px",
                        paddingRight: "5px",
                      }}
                    >
                      <span>Book Now</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </>
          </>
        )}
      </div>
    </>
  );
};

export default Navbar2;
