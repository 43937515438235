import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Footer from "../../Home/Footer/Footer";
import Metatags from "../../MetaTags/Metatags";
import SliderInner from "../../SliderInner/SliderInner";

function BlogDetails() {
  const params = useParams();
  const [blogDetails, setBlogDetails] = useState();
  const [loading, setLoading] = useState(true);
  const reference = useRef(null);
  const executeScroll = () => reference.current.scrollIntoView();

  useEffect(() => {
    fetchData();
    executeScroll();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const result = await axios(
      `https://miracleinside.info/wp-json/wp/v2/blogs/${params.id}`
    );
    console.log(result.data);
    if (result.data?.id) {
      setBlogDetails(result.data);
    }
    setLoading(false);
  };

  return (
    <>
      <>
        <SliderInner image={blogDetails?.acf.imageurl} />
        <section ref={reference} className="about-layout1 pb-0">
          {blogDetails?.id ? (
            <div className="container">
              <Metatags
                title={blogDetails.acf?.meta_title}
                description={blogDetails.acf?.meta_description}
                keyword={blogDetails.acf?.meta_keyword}
              />

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="heading-layout2">
                    <h1 className="heading__title mb-40">
                      {blogDetails.title.rendered}
                    </h1>
                  </div>
                  {/* /heading */}
                </div>
              </div>
              {/* /.row */}
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div
                    className="about__Text"
                    dangerouslySetInnerHTML={{
                      __html: blogDetails.content.rendered,
                    }}
                  ></div>
                </div>
                {/* /.col-lg-6 */}
              </div>
              {/* /.row */}
            </div>
          ) : null}
          {/* /.container */}
        </section>
      </>
      {loading && (
        <div
          className="row justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <h6>Loading...</h6>
        </div>
      )}
      <Footer />
    </>
  );
}

export default BlogDetails;
