import React, { useEffect, useRef, useState } from "react";
import Footer from "../Home/Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { convertDateToFormat } from "../Utils/ConvertDateToFormat";
import Metatags from "../MetaTags/Metatags";

export default function ScanBookingForm() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [allScans, setAllScans] = useState([]);
  const [formData, setFormData] = useState({
    "first-name": "",
    "last-name": "",
    "your-email": "",
    "your-weeks": "",
    "your-address": "",
    "your-postcode": "",
    "your-gender": "",
    "your-mobile": "",
    "your-hear": "",
    "your-timetocontact": "",
    "your-package": params?.name,
  });

  const [dateOfAppointment, setDateOfAppointment] = useState();
  const [dueDate, setDueDate] = useState();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [isVerified, setIsVerified] = useState(false);

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    getScanList();
  }, []);

  const getScanList = async () => {
    const response = await axios(
      "https://miracleinside.info/wp-json/wp/v2/scans?per_page=100"
    );
    if (response?.data) {
      setAllScans(response.data);
      console.log(response.data);
    } else alert("Something went wrong please check your internet connection");
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setLoading(true);
    if (!isVerified) {
      alert("Please verify captcha to continue");
      setLoading(false);
      return;
    }
    onFormSubmit();

    setValidated(true);
  };
  const inputOnchange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  const onFormSubmit = async () => {
    console.log("submit form is running");
    var form_data = new FormData();
    Object.keys(formData).forEach((key) =>
      form_data.append(key, formData[key])
    );
    form_data.append("your-due", convertDateToFormat(dueDate));
    form_data.append(
      "your-appointmentdate",
      convertDateToFormat(dateOfAppointment)
    );
    try {
      const response = await axios.post(
        "https://miracleinside.info/wp-json/contact-form-7/v1/contact-forms/336/feedback",
        form_data
      );
      console.log(response);
      if (response.data?.status == "mail_sent") {
        setSuccessMessage(response.data.message);
        executeScroll();
      } else {
        alert(response.data.message);
      }
      setLoading(false);
    } catch {
      alert("something went wrong.");
      setLoading(false);
    }
  };
  const onChange = (val) => {
    console.log("captcha function is running");
    setIsVerified(true);
  };

  return (
    <>
      <Metatags title={"Scan Booking"} />

      <SliderInner />
      <section>
        <div className="container">
          <>
            <div className="main-content">
              <h2>&nbsp;Scan Booking Enquiry Form</h2>
              <p ref={myRef}>
                All of Our B<strong>aby Scans in Yorkshire&nbsp;</strong>are
                carried out by our Owner-Cum Sonographer who is Experienced,
                Fully qualified in Obstetric Ultrasound in our comfortable and
                relaxing environment. We even offer{" "}
                <strong>3D/4D Scan online booking</strong> at affordable prices.
                <span>&nbsp;</span>
              </p>
              <ul>
                <li>
                  <span>Please fill out the below enquiry form,&nbsp;</span>Your
                  email address only for us to contact you. No information
                  gathered from this page is transmitted to any third parties.
                </li>
                <li>
                  <span>
                    <span>
                      Our staff will call you at your preferred time ,&nbsp;
                    </span>
                  </span>
                  Alternatively, you can call our bookings team on&nbsp;
                  <span>01132008760 / 01133226110&nbsp;directly&nbsp;</span>to
                  book your appointment.
                </li>
              </ul>
              <p>
                &nbsp;
                <em>
                  <strong>Please note:</strong>
                </em>
                <strong>&nbsp;</strong>
              </p>
              <ul>
                <li>
                  Please arrive 15 minutes before your appointment time to
                  completethe necessary pre questionnaire&nbsp;
                </li>
                <li>
                  All Extras item and safe-keeps can be requested on the day of
                  your scan and paid after your scan Appointment.
                </li>
                <li>
                  <span>
                    Our online booking system is under maintenance. We are very
                    sorry for the inconvenience.
                  </span>{" "}
                  , <span></span>please call our{" "}
                  <strong>3D/4D Scan Online</strong>
                  <span>&nbsp;</span> Booking team on 01132008760 /
                  01133226110&nbsp;directly .
                </li>
              </ul>
            </div>
          </>
          {successMessage ? (
            <h4>{successMessage}</h4>
          ) : (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <div className="form-group">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="first-name"
                      required
                      type="text"
                      placeholder="First name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <div className="form-group">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="last-name"
                      required
                      type="text"
                      placeholder="Last name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="formGroupEmail">
                  <div className="form-group">
                    <Form.Label>Email</Form.Label>

                    <Form.Control
                      onChange={inputOnchange}
                      name="your-email"
                      type="email"
                      placeholder="Enter email"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a email address.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom03">
                  <div className="form-group">
                    <Form.Label>Current Weeks Pregnant</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-weeks"
                      type="text"
                      placeholder="8"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid number.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom04">
                  <div className="form-group">
                    <Form.Label>Due Date</Form.Label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM-DD-YYYY"
                      selected={dueDate}
                      onChange={(date) => setDueDate(date)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid date.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-address"
                      type="text"
                      placeholder="Address"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a address.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Post Code</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-postcode"
                      type="text"
                      placeholder="Post Code"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid post code.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Preferred Appointment Date</Form.Label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM-DD-YYYY"
                      selected={dateOfAppointment}
                      onChange={(date) => setDateOfAppointment(date)}
                      required
                    />{" "}
                    <Form.Control.Feedback type="invalid">
                      Please provide a Appointment Date.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Preferred Package</Form.Label>
                    {allScans?.length > 0 ? (
                      <Form.Select
                        onChange={inputOnchange}
                        name="your-package"
                        className="form-control"
                        defaultValue={params?.name}
                      >
                        {allScans?.map((scan) => (
                          <option key={scan.id} value={scan.title.rendered}>
                            {scan.title.rendered}
                          </option>
                        ))}
                      </Form.Select>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Do you want know the gender?</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-gender"
                      type="text"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-mobile"
                      type="text"
                      placeholder="Mobile"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Mobile Number
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>Best Time To Contact You</Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-timetocontact"
                      type="text"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please select
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4" controlId="validationCustom05">
                  <div className="form-group">
                    <Form.Label>
                      How did you hear about Miracle Inside?
                    </Form.Label>
                    <Form.Control
                      onChange={inputOnchange}
                      name="your-hear"
                      type="text"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Row>
              <Form.Group className="mb-4">
                <div className="form-group">
                  <Form.Check
                    required
                    label="I agree to the following Cancellation Policy"
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                </div>
              </Form.Group>
              <Form.Label>
                Cancellation policy: No cancellations or changes allowed within
                72 hours of the appointment and More details can be found in our
                Policies page - If you would like to Amend your Appointment then
                please call us our Booking team on 01132008760 / 01133226110
                directly. or email : admin@miracleinside.com
              </Form.Label>
              <ReCAPTCHA
                className="mb-4 mt-4"
                sitekey="6Lc_lg8UAAAAANqdYBoOiGrlf1mclhIJx9f3-4vF"
                onChange={onChange}
              />

              <Button disabled={loading} type="submit">
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Form>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
}
