import React, { useState, useEffect } from "react";
import Footer from "../../Home/Footer/Footer";
import SliderInner from "../../SliderInner/SliderInner";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import Metatags from "../../MetaTags/Metatags";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { metaDataApi } from "../../../utils/metaDataApi";

var isDataFilled = false;

export default function Blog() {
  const [metaData, setMetaData] = useState({
    name: "",
    description: "",
  });

  const getMetaResp = async () => {
    const metaData = await metaDataApi(582);
    if (metaData !== -1) {
      setMetaData({
        name: metaData.acf.meta_title,
        description: metaData.acf.meta_description,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaResp();
  }, []);

  const { data, status } = useQuery("blogs", () =>
    axios("https://miracleinside.info/wp-json/wp/v2/blogs/?per_page=100")
  );

  const TruncateLongString = (str) => {
    return str.length > 88 ? str.slice(0, 165 - 1) + "&hellip;" : str;
  };
  const getFormattedDate = (item) => {
    if (item !== "") {
      return new Date(item.modified).toLocaleDateString();
    }
  };
  const [bannerimg, setBannerimg] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://miracleinside.info/wp-json/wp/v2/homeslider/372"
      );
      if (result.data?.id) {
        setBannerimg(result.data);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="canonical" href="https://miracleinside.com/blogs" />
        </Helmet>
      </HelmetProvider>

      <Metatags title={metaData?.name} description={metaData?.description} />

      <SliderInner image={bannerimg?.acf.slider_image_url} />
      <section className="blog-grid">
        <div className="container">
          <h1>Pregnancy, Scan, Blood Test, and Health - Blog</h1>
          <div className="row">
            {/* Post Item #1 */}
            {data?.data.map((item, index) => (
              <div key={index} className="col-sm-12 col-md-6 col-lg-4">
                <div className="post-item">
                  <div className="post__img">
                    <Link to={`/blogs/${item?.id}/${item?.slug}`}>
                      <img
                        src={item.acf.imageurl}
                        alt="post image"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  {/* /.post__img */}
                  <div className="post__body">
                    <div className="post__meta-cat">
                      <Link to={`/blogs/${item?.id}/${item?.slug}`}>
                        {item.acf.category}
                      </Link>
                    </div>
                    {/* /.blog-meta-cat */}
                    <div className="post__meta d-flex">
                      <span
                        className="post__meta-date"
                        dangerouslySetInnerHTML={{
                          __html: getFormattedDate(item),
                        }}
                      ></span>
                      <Link
                        to={`/blogs/${item?.id}/${item?.slug}`}
                        className="post__meta-author"
                        dangerouslySetInnerHTML={{
                          __html: item.acf.written_by,
                        }}
                      ></Link>
                    </div>
                    <h4 className="post__title">
                      <Link
                        to={`/blogs/${item?.id}/${item?.slug}`}
                        dangerouslySetInnerHTML={{
                          __html: item.title.rendered,
                        }}
                      ></Link>
                    </h4>
                    <p
                      className="post__desc"
                      dangerouslySetInnerHTML={{
                        __html: TruncateLongString(item.content.rendered),
                      }}
                    ></p>
                    <Link
                      to={`/blogs/${item?.id}/${item?.slug}`}
                      className="btn btn__secondary btn__link btn__rounded"
                    >
                      <span>Read More</span>
                      <i className="icon-arrow-right" />
                    </Link>
                  </div>
                  {/* /.post__body */}
                </div>
                {/* /.post-item */}
              </div>
            ))}
            {/* /.col-lg-4 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-12 text-center">
              <nav className="pagination-area">
                <ul className="pagination justify-content-center">
                  <li>
                    <a className="current" href="#">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-arrow-right" />
                    </a>
                  </li>
                </ul>
              </nav>
              {/* .pagination-area */}
            </div>
            {/* /.col-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      <Footer />
    </>
  );
}
