import React, { useEffect, useState } from "react";
import Footer from "../Home/Footer/Footer";
import axios from "axios";
import SliderInner from "../SliderInner/SliderInner";
import Metatags from "../MetaTags/Metatags";
import { metaDataApi } from "../../utils/metaDataApi";

function About({ id }) {
  const [data, setdata] = useState();
  const [metaData, setMetaData] = useState({
    name: "",
    description: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://miracleinside.info/wp-json/wp/v2/pages/${id}`
      );
      if (result?.data) {
        setdata(result.data);
      }
    };

    fetchData();
    getMetaResp();
  }, [id]);

  const getMetaResp = async () => {
    const metaData = await metaDataApi(id);
    if (metaData !== -1) {
      setMetaData({
        name: metaData.acf.meta_title,
        description: metaData.acf.meta_description,
      });
    }
  };
  return (
    <>
      <Metatags title={metaData?.name} description={metaData?.description} />

      <SliderInner image={data?.acf.image_url} />

      <section className="about-layout1 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="heading-layout2">
                <h1
                  className="heading__title mb-40"
                  dangerouslySetInnerHTML={{ __html: data?.title.rendered }}
                ></h1>
              </div>
              {/* /heading */}
            </div>
            {/* /.col-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="about__Text">
                <p
                  className="mb-30"
                  dangerouslySetInnerHTML={{ __html: data?.content.rendered }}
                ></p>
              </div>
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <Footer />
    </>
  );
}

export default About;
