import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Home/Footer/Footer";
import Metatags from "../../MetaTags/Metatags";
import SliderInner from "../../SliderInner/SliderInner";

const FertilityClinicDetails = () => {
  const params = useParams();
  const [clinic, setClinic] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://miracleinside.info/wp-json/wp/v2/fertility_clinics/${params.id}`
      );
      if (result.data?.id) {
        setClinic(result.data);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <SliderInner image={clinic?.acf.image} />
      {clinic?.id ? (
        <section className="about-layout1 pb-0">
          <Metatags
            title={clinic.title?.rendered}
          />
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="heading-layout2">
                  <h1 className="heading__title mb-40">
                    {clinic.title.rendered}
                  </h1>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="text-right">
                  <a
                    href={clinic.acf.website_link}
                    className="btn btn__secondary btn__rounded mr-30"
                  >
                    <span> Visit Website</span>
                    <i className="icon-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div
                  className="about__Text"
                  dangerouslySetInnerHTML={{ __html: clinic.content.rendered }}
                ></div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          className="row justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <h6>Loading...</h6>
        </div>
      )}
      <Footer />
    </>
  );
};

export default FertilityClinicDetails;
