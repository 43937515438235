import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function About() {
  const [home, setHome] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        "https://miracleinside.info/wp-json/wp/v2/posts/42"
      );
      
      if (result.data?.id) {
        setHome(result.data);
      }
      
    };

    fetchData();
  }, []);


  return (
    <>
    {home?.id ? (
         <section className="about-layout1 pb-0">
           <div className="container">
             <div className="row">
               <div className="col-sm-12 col-md-12 col-lg-6">
                 <div className="heading-layout2">
                   <h2 className="heading__title mb-40">
                     About Us
                   </h2>
                 </div>
                 {/* /heading */}
               </div>
               {/* /.col-12 */}
             </div>
             {/* /.row */}
             <div className="row">
               <div className="col-sm-12 col-md-12 col-lg-6">
                 <div className="about__Text" dangerouslySetInnerHTML={{ __html: home.content.rendered }}>
 
                 
                 </div>
                 <div class="d-flex align-items-center mb-30"><a class="btn btn__primary btn__outlined btn__rounded mr-30" href="/318/about">
 Read More
 </a></div>
               </div>
               {/* /.col-lg-6 */}
               <div className="col-sm-12 col-md-12 col-lg-6">
                 <div className="video-banner">
                   <img src="images/about/maxresdefault.jpeg" alt="about" />
                   <a
                     className="video__btn video__btn-white popup-video" target="_blank"
                     href={ home.acf.youtube_link}
                   >
                     <div className="video__player">
                       <i className="fa fa-play" />
                     </div>
                   </a>
                 </div>
                 {/* /.video-banner */}
               </div>
               {/* /.col-lg-6 */}
             </div>
             {/* /.row */}
           </div>
           {/* /.container */}
         </section>
   ):(
     <div
     className="row justify-content-center align-items-center"
     style={{ height: "80vh" }}
   >
     <h6>Loading...</h6>
   </div>
 
   )}
   </>
   )
   

}
